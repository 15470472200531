/* @flow */

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import SystemMessages from "components/SystemMessages";
import Wrapper from "components/Wrapper";
import Logo, { SecondaryLogo } from "components/AppHeader/Logo";
import useIsSmall from "helpers/use-is-small";
import { getFavicons } from "helpers/get-meta";
import CookieConsent from "components/CookieConsent";
import Button from "components/Button";
import { CmsData } from "data";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { useSendMessage } from "crustate/react";
import { setMode, MODE } from "state/view-mode";

import styles from "./styles.scss";

type Props = {
  children: React$Node,
  location: Location,
};

const GuestApp = ({ children, location }: Props) => {
  const {
    info,
    configuration,
    content: { loginview },
    routes,
  } = useContext(StoreInfoContext);

  const isSmall = useIsSmall(parseInt(styles.small, 10));
  const t = useTranslate();
  const sendMessage = useSendMessage();

  const style = {
    background: loginview.imageBackground ? `url(${loginview.imageBackground})` : "",
    backgroundColor: "var(--primaryAlt)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundSize: "cover",
  };

  const termsLink = routes.termsView && routes.termsView.toggle !== undefined &&
  routes.termsView.toggle && routes.termsView.url !== undefined &&
  routes.termsView.url && routes.termsView.title !== undefined && routes.termsView.title ?
    {
      title: routes.termsView.title,
      url: routes.termsView.url,
    } : null;

  const shopTermsUrl = (configuration.customShopTermsUrl &&
    configuration.hasCustomShopTermsUrl === true) ?
    configuration.customShopTermsUrl : "checkout-terms";

  const loginViewLogoSrc = loginview.logo || "";
  const consentActivated = (configuration.showCookieConsent !== null ||
    configuration.showCookieConsent !== undefined) &&
    configuration.showCookieConsent === true;

  return (
    <CmsData.Provider url="cookie-consent">
      <div style={style} className={cn(styles.block, "awardit-guestApp")}>
        <Helmet
          titleTemplate={`${configuration.pageTitlePrefix || ""} %s ${configuration.pageTitleSuffix || ""}`}
          link={getFavicons(configuration)}
          htmlAttributes={{ lang: info.locale.slice(0, 2) }}
        />
        <header className={styles.header}>
          <Wrapper className={styles.headerWrapper}>
            <Link to="/">
              <Logo
                isInverted
                width={isSmall ? configuration.logoWidthSmall : configuration.logoWidthLarge}
                loginViewLogo={loginViewLogoSrc}
              />
            </Link>
            {loginview.customerServiceButtonUrl && loginview.customerServiceButtonText &&
            <nav>
              <a
                className={cn("awardit-guestLink")}
                href={loginview.customerServiceButtonUrl}
              >
                {loginview.customerServiceButtonText}
              </a>
            </nav>
            }
          </Wrapper>
        </header>
        <div className={styles.wrapper}>
          {children}
          <SystemMessages />
        </div>
        {location.pathname === routes.loginView?.url &&
          <CookieConsent />
        }
        <div className={styles.footer}>
          {location.pathname === routes.loginView?.url &&
            <ul className={styles.footerLinks}>
              {consentActivated && (
                <li>
                  <Button
                    className={cn(
                      { [styles.spacing]: loginview.customerServiceButtonUrl },
                      styles.cookieButton
                    )}
                    onClick={() => sendMessage(setMode(MODE.COOKIE_CONSENT))}
                  >
                    {t("FOOTER.NEED_HELP.COOKIES")}
                  </Button>
                </li>
              )}
              <li>
                <Link className={styles.link} to={termsLink?.url}>
                  {termsLink?.title}
                </Link>
              </li>
              {Boolean(configuration.showPrivacyNotice) &&
                <li>
                  <Link className={styles.link} to="privacy-notice">
                    {t("FOOTER.LEGAL.PRIVACY_NOTICE")}
                  </Link>
                </li>
              }
              {Boolean(loginview.showShopTermsLink) &&
                <li>
                  <Link className={styles.link} to={shopTermsUrl}>
                    {t("FOOTER.LEGAL.TERMS")}
                  </Link>
                </li>
              }
            </ul>
          }
          <SecondaryLogo
            width={isSmall ? configuration.logoWidthSmall :
              configuration.logoWidthLarge}
            className="awardit-loginSecondaryLogo" />
        </div>
      </div>
    </CmsData.Provider>
  );
};

export default GuestApp;

/* @flow */

export const ROUTES = [
  "offerListView",
  "earnView",
  "accountTinkView",
  "redeemView",
  "newsListView",
  "questionnaireView",
  "currentInfoView1",
  "currentInfoView2",
  "currentInfoView3",
  "currentInfoView4",
  "currentInfoView5",
  "currentInfoView6",
  "leaderboardView",
  "reportsView",
  "quizList",
  "teamPot",
];

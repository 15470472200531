/* @flow */

import { useContext, useMemo } from "react";
import { useLocation } from "react-router";
import { StoreInfoContext } from "entrypoint/shared";

const useOnHeroRoute = () => {
  const { routes } = useContext(StoreInfoContext);
  const { pathname } = useLocation();

  const HERO_ROUTES = useMemo(() => ([
    ...routes.newsListView && routes.newsListView.url ? [routes.newsListView.url] : [],
    ...routes.questionnaireView && routes.questionnaireView.url ?
      [routes.questionnaireView.url] : [],
    ...routes.quizList && routes.quizList.url ? [routes.quizList.url] : [],
    ...routes.currentInfoView1 && routes.currentInfoView1.url ? [routes.currentInfoView1.url] : [],
    ...routes.currentInfoView2 && routes.currentInfoView2.url ? [routes.currentInfoView2.url] : [],
    ...routes.currentInfoView3 && routes.currentInfoView3.url ? [routes.currentInfoView3.url] : [],
    ...routes.currentInfoView4 && routes.currentInfoView4.url ? [routes.currentInfoView4.url] : [],
    ...routes.currentInfoView5 && routes.currentInfoView5.url ? [routes.currentInfoView5.url] : [],
  ]), [routes]);

  return HERO_ROUTES.some(route => pathname.includes(route));
};

export default useOnHeroRoute;

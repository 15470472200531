export const host = "localhost";
export const services = {
  magento: {
    host: typeof process !== "undefined" ? process.env.MAGENTO_HOST : null,
    url: typeof process !== "undefined" ? process.env.MAGENTO_URL : null,
    headers: ["SSL", "Ssl-Offload", "X-Forward-Proto", "X-Real-Ip", "X-Forwarded-For", "Cookie", "X-Datadog-Trace-id", "X-Datadog-Parent-Id", "X-Datadog-Origin", "X-Datadog-Sampling-Policy", "X-Datadog-Sampled"],
  },
  awardit: {
    url: typeof process !== "undefined" ? process.env.AWARDIT_URL : null,
    partnerId: typeof process !== "undefined" ? process.env.PARTNER_ID : null,
    jwtSecret: "fR+8nYFKrP14juo7mj5HwsDLsb0V9GHyz6lh9IuaIcU=",
  },
};

export const PREFIX = typeof process !== "undefined" && process.env.PREFIX ? process.env.PREFIX : "";
// export const JSPParserProxyURL = "https://ahlsell.awardit.com";
export const JSPParserProxyURL = typeof process !== "undefined" ? process.env.JSP_PARSER_PROXY_URL : null;

export const googleAnalytics = typeof process !== "undefined" ? [process.env.GA_ANALYTICS] : [];
export const TINK_PROVIDER_VALID_DAYS = 90;
export const proxyHosts = null;
// Set proxyHost to skip local federation
export const proxyHost = null;
export const datadog = {
  applicationId: 'e1999093-b49e-4df7-9043-5199a2b86141',
  clientToken: 'pub7f5e3ff829283e61039b55cc5ec76911',
  site: 'datadoghq.eu',
  service:'awardit-blueberry',
  env:'production',
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingOrigins: [
    /https:\/\/a-klubben\.ahlsell\.se/,
    /https:\/\/.*\.awardit\.com/,
    /https:\/\/socialclub\.gramgroup\.se/,
  ],
  cspUri: "https://csp-report.browser-intake-datadoghq.eu/api/v2/logs?dd-api-key=pub552607268219ad5b8d2647030dd2f46f&dd-evp-origin=content-security-policy&ddsource=csp-report&ddtags=service%3Ablueberry.awardit.com%2Cenv%3Aproduction",
};
export const contentSecurityPolicy = null;
export const contentSecurityPolicyReportOnly = typeof process !== "undefined" && process.env.CSP_REPORT_ONLY ? process.env.CSP_REPORT_ONLY : false;
